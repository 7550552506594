import React, { useEffect, useState, useRef } from "react";

import {
  python,
  postgresql,
  mysql,
  mongodb,
  docker,
  css3,
  html5,
  jquery,
  redux,
  github,
  socket,
  oauth,
  flask,
  heroku,
  git,
  npm,
  vscode,
  postman,
  restapi,
  figma,
  nginx,
} from "../../Assets";

import { FaBootstrap, FaReact } from "react-icons/fa";
import { SiTailwindcss, SiDjango, SiFramer, SiPycharm } from "react-icons/si";
import { DiJavascript1 } from "react-icons/di";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Skills = () => {
  const [tab, setTab] = useState(0);
  const tabAnimate = useAnimation();
  const { ref, inView } = useInView();
  const firstRender = useRef();

  useEffect(() => {
    firstRender.current === false
      ? (firstRender.current = false)
      : (firstRender.current = true);
    if (inView && firstRender.current) {
      tabAnimate.start("visible");
      tabAnimate.start("show");
      firstRender.current = false;
    }
  }, [inView, tabAnimate]);
  useEffect(() => {
    if (inView) {
      tabAnimate.set("visible");
      tabAnimate.start("show");
    }
  }, [tab, tabAnimate]);

  const initialRender = {
    hidden: {
      y: 24,
      opacity: 0,
    },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: `${i * 0.2}`,
        duration: 0.5,
      },
    }),
  };
  const divVariants = {
    hide: {
      opacity: 0,
      transition: {
        duration: 0.01,
      },
      x: -100,
    },
    show: (i = 1) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.065,
      },
    }),
  };
  const skills = [
    [
      {
        name: "Django",
        png: <SiDjango size={50} color="white" />,
        bg: "bg-[#092D1F]",
        textColor: "text-white",
        title: "Django",
      },
      {
        name: "Flask",
        png: <img src={flask} className="w-[50px] md:w-[60px]" alt="" />,
        bg: "bg-[white]",
        textColor: "text-black",
        title: "Flask",
      },
      {
        name: "PostgreSQL",
        png: <img src={postgresql} className="w-[50px] md:w-[60px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "PostgreSQL",
      },
      {
        name: "MySQL",
        png: <img src={mysql} className="w-[90px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "",
      },
      {
        name: "MongoDB",
        png: <img src={mongodb} className="w-[70px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "Mongodb",
      },
      {
        name: "RestAPI",
        png: <img src={restapi} className="w-[50px] md:w-[70px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "",
      },
      {
        name: "OAuth",
        png: <img src={oauth} className="w-[50px] md:w-[70px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "OAuth",
      },
      {
        name: "Socket.io",
        png: <img src={socket} className="w-[50px] md:w-[70px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "Socket.io",
      },
    ],
    [
      {
        name: "React",
        png: <FaReact size={60} color="#1973B7" />,
        bg: "bg-gradient-to-b from-[#0071CB] to-[#0D161D]",
        textColor: "text-white",
        title: "React",
      },
      {
        name: "Redux",
        png: <img src={redux} className="w-[50px] md:w-[70px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "Redux",
      },
      {
        name: "Jquery",
        png: <img src={jquery} className="w-[50px] md:w-[70px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "Jquery",
      },
      {
        name: "HTML",
        png: <img src={html5} className="w-[50px] md:w-[70px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "HTML",
      },
      {
        name: "Css",
        png: <img src={css3} className="w-[50px] md:w-[70px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "CSS",
      },
      {
        name: "Bootstrap",
        png: <FaBootstrap size={70} color="#8512FB" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "Bootstrap",
      },
      {
        name: "TailwindCss",
        png: <SiTailwindcss size={60} color="##46ABB3" />,
        bg: "bg-[#0b1120]",
        textColor: "text-white",
        title: "TailwindCss",
      },
      {
        name: "Framer Motion",
        png: <SiFramer size={50} color="#EEEEEE" />,
        bg: "bg-[#1A1A1A]",
        textColor: "text-white",
        title: "Framer Motion",
      },
    ],
    [
      {
        name: "Docker",
        png: <img src={docker} className="w-[50px] md:w-[90px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "Docker",
      },
      {
        name: "Nginx",
        png: <img src={nginx} className="w-[50px] md:w-[90px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "Nginx",
      },
      {
        name: "Git",
        png: <img src={git} className="w-[50px] md:w-[70px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "Git",
      },
      {
        name: "Github",
        png: <img src={github} className="w-[50px] md:w-[70px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "Github",
      },
      {
        name: "Heroku",
        png: <img src={heroku} className="w-[50px] md:w-[70px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "Heroku",
      },
      {
        name: "npm",
        png: <img src={npm} className="w-[50px] md:w-[70px]" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "npm",
      },
    ],
    [
      {
        name: "JavaScript",
        png: <DiJavascript1 size={40} color="black" />,
        bg: "bg-yellow-500",
        textColor: "text-black",
        title: "JavaScript",
      },
      {
        name: "Python",
        png: <img src={python} className="w-[40px] md:w-[50px]" alt="" />,
        bg: "bg-[#1F4362]",
        textColor: "text-white",
        title: "Python",
      },
    ],
    [
      {
        name: "Pycharm",
        png: <SiPycharm size={40} color="black" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "Pycharm",
      },
      {
        name: "Vscode",
        png: <img src={vscode} width="40" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "Vscode",
      },
      {
        name: "Postman",
        png: <img src={postman} width="40" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "Postman",
      },
      {
        name: "Figma",
        png: <img src={figma} width="40" alt="" />,
        bg: "bg-white",
        textColor: "text-black",
        title: "Figma",
      },
    ],
  ];

  const skillTabs = [
    "Backend",
    "Frontend",
    "Development",
    "Languages",
    "Tools",
  ];

  return (
    <div
      ref={ref}
      id="Skills"
      className="flex justify-center mt-24 px-6 md:px-20 lg:px-40 2xl:px-80"
    >
      <div className="flex flex-col w-full space-y-20 py-12">
        <motion.h1
          variants={initialRender}
          custom={1}
          initial="hidden"
          animate={tabAnimate}
          className="text-center text-[28px] lg:text-[48px] font-bold leading-none "
        >
          Technologies
          <span className="bg-gradient-to-r from-red-500 to-slate-800 bg-clip-text text-transparent">
            {" "}
            I've worked with
          </span>
        </motion.h1>
        <motion.div className="flex flex-col">
          <motion.ul
            variants={initialRender}
            custom={2}
            initial="hidden"
            animate={tabAnimate}
            className="flex space-x-2 sm:space-x-0 flex-wrap md:space-x-4"
          >
            {skillTabs.map((i, index) => {
              return (
                <li
                  key={index}
                  className={`p-2 grow duration-500 text-center border-b-2 hover:text-[#10C296] hover:cursor-pointer text-[16px] lg:text-[18px] lg:p-3} ${
                    tab === index
                      ? "border-[#10C296] text-[#10C296]"
                      : "border-transparent"
                  }`}
                  onClick={() => {
                    setTab(index);
                    tab !== index && tabAnimate.start("hide");
                  }}
                >
                  {i}
                </li>
              );
            })}
          </motion.ul>
          <motion.ul
            variants={initialRender}
            custom={3}
            initial="hidden"
            animate={tabAnimate}
            className={`flex flex-wrap py-12 px-2 sm:px-8 gap-4 rounded-lg bg-gradient-to-b from-black via-white to-black overflow-x-hidden`}
          >
            {skills[tab].map((name, id) => {
              return (
                <motion.li
                  key={id}
                  custom={id}
                  initial="hide"
                  animate={tabAnimate}
                  variants={divVariants}
                  whileHover={{
                    scale: [1, 1.2],
                    transition: { duration: 0.2 },
                  }}
                  className={`flex flex-col justify-center items-center space-y-2 bg-white py-4 rounded-xl font-normal text-sm h-24 w-24 md:h-32 md:w-32 hover:cursor-pointer shadow-black shadow-2xl ${name.bg} ${name.textColor} opacity-50`}
                >
                  {name.png} <span className="">{name.title}</span>
                </motion.li>
              );
            })}
          </motion.ul>
        </motion.div>
      </div>
    </div>
  );
};

export { Skills };
