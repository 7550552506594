import "./App.css";
import Nav from "./Components/Nav";
import { NavBar } from "./Components/NavBar";
import ScrollToTop from "./Components/ScrollToTop";
import SocialSideBar from "./Components/SocialSideBar";
import { useCycle } from "framer-motion";
import { Home, Portfolio, Skills, Contact, Footer, About, Experience } from "./Pages";


function App() {
  const [toggle, setToggle] = useCycle(false, true);

  
  return (
    
    <div className="App flex font-sourceSansPro bg-black text-white">
      <SocialSideBar />
      <ScrollToTop />
      <NavBar toggle={toggle} setToggle={setToggle}/>

      <div className= {`overflow-x-hidden duration-300 ${toggle && ' blur-[2px]'} z-0`}>
        <Nav />
        <Home/>
        <About />
        <Skills />
        <Experience />
        <Portfolio />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;
