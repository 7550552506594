import { React, useState } from "react";

const VerticalTab = () => {
  const [tab, setTab] = useState(0);
  const company = [
    {
      Company: "Ensemble Matrix",
      Duration: "Jul 2022 - Present",
      Position: "Python Developer and Automation Engineer",
      Work: [
        "Worked on a cheque clearing automation product.",
        "Working on a bot that scrapes cheque data and images using selenium and beautifulsoup.",
        "Working on core backend logic for the system in django.",
        "Containerized and deploying the entire system into production using docker.",
      ],
    },
    {
      Company: "VolgAI Pvt. Ltd.",
      Duration: "Feb 2021 - Apr 2022",
      Position: "Python/Django Developer",
      Work: [
        "Created Restful API services with Django and Flask.",
        "Worked on authentication system such a JWT and Session Authentication System.",
        "Write, review and unit-test production-level code using python on daily basis.",
        "Assisted the Senior Developer in designing and developing custom web applications throughout the project life - cycle.",
        "Worked on Chatbot system developed with Rasa framework.",
        "Handled programming tasks for and maintained 2 internal websites with a high success rate in product update deployment.",
      ],
    },
  ];
  return (
    <div className="flex w-full h-fit ">
      <div className=" w-1/4 hidden md:block  ">
        <ul className="flex flex-col text-right">
          {company.map((i, index) => {
            return (
              <li
                key={index}
                onClick={() => setTab(index)}
                className={`p-4 cursor-pointer text-2xl font-semibold border-r-2 border-white duration-300 hover:text-[#E24243] ${
                  tab === index && "border-[#E24243] border-r-2 text-[#E24243] "
                } `}
              >
                {i.Company}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="hidden w-full justify-center items-center text-left md:flex">
        <div className="flex flex-col w-11/12 space-y-6">
          <div className="flex flex-col w-full">
            <h1 className="text-xl font-bold text-[#10C296]">{company[tab].Position}</h1>
            <h3 className="text-sm font-semibold">{company[tab].Duration}</h3>
          </div>
          <ul className="list-disc list-inside">
            {company[tab].Work.map((i, id) => {
              return (
                <li
                  key={id}
                  className=" text-base font-semibold py-1 leading-normal"
                >
                  {i}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="flex flex-col md:hidden text-left space-y-12">  
      {company.map((i, id) => {
        return (
          <div key={id} className="flex flex-col w-11/12 space-y-5">
            <h1 className="cursor-pointer text-2xl font-semibold text-[#E24243]">{i.Company}</h1>
            <div className="flex flex-col w-full">
              <h1 className="text-lg font-medium text-[#10C296]">{i.Position}</h1>
              <h3 className="text-sm font-normal">{i.Duration}</h3>
            </div>
            <ul className="list-disc list-inside">
              {i.Work.map((i, id) => {
                return (
                  <li
                    key={id}
                    className=" text-base font-semibold py-1 leading-normal"
                  >
                    {i}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
      </div>    
    </div>
  );
};

export default VerticalTab;
