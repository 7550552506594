import React from "react";
import { BsFacebook, BsLinkedin, BsGithub } from "react-icons/bs";

const SocialSideBar = () => {
  const social = [
    {
      title: "Linkedin",
      link: "https://www.linkedin.com/in/anishpdhn/",
      bg: "bg-blue-600",
      logo: <BsLinkedin size={30} />,
    },
    {
      title: "Facebook",
      link: "https://www.facebook.com/anish.pradhan.902266",
      bg: "bg-[#0e8ef1]",
      logo: <BsFacebook size={30} />,
    },
    {
      title: "Github",
      link: "https://github.com/anishpradhan",
      bg: "bg-[#333333]",
      logo: <BsGithub size={30} />,
    },
  ];
  return (
    <div className="contact hidden lg:flex flex-col fixed top-[35%] left-0 text-xl z-10">
      <ul className="rounded-r-lg overflow-hidden">
        {social.map((i, index) => (
          <li
          key={index}
            className={`w-[160px] h-[60px] flex ${i.bg} ml-[-100px] hover:ml-[0px] duration-300`}
          >
            <a
              href={i.link}
              target={"_blank"}
              rel="noreferrer"
              className="flex justify-around items-center w-full px-4 space-x-4"
            >
              <span className="grow">{i.title}</span> <span>{i.logo}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SocialSideBar;
