import React from "react";

const Footer = () => {
  return (
    <div
      id="Footer"
      className="flex justify-evenly mt-24 px-6 md:px-20 lg:px-40 2xl:px-72 py-10"
    >
    <div className="flex w-full justify-between">
      <div className="flex flex-col space-y-4">
        <span className="w-fit text-[14px] sm:text-[20px] font-bold leading-[15px]">ANISH PRADHAN</span>
        <span className="w-fit text-[14px] sm:text-[15px] font-bold leading-[15px]">FullStack Developer</span>
      </div>
      
        <span className="text-[12px] md:text-[15px]  font-bold">© 2021 Anish Pradhan. All rights reserved.</span>
        </div>
    </div>
  );
};

export {Footer};
