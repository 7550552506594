import React from "react";
import { motion } from "framer-motion";
import { illustration } from "../../Assets";

const variants = {
  hidden: {
    y: 50,
    opacity: 0,
  },
  visible: (i) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: `${i * 0.3}`,
      duration: 0.5,
    },
  }),
};

const Home = () => {
  return (
    <div
      id="Home"
      className={`py-16 xl:py-36 px-6 flex justify-center min-h-screen`}
    >
      <div className="relative flex xl:flex-row justify-between flex-col space-y-4 xl:space-y-0 min-w-[78%]"
       >
        <motion.div
          className="xl:basis-1/3 basis:2/3"
          variants={variants}
          initial="hidden"
          animate="visible"
        >
          <div className="xl:absolute flex-col space-y-8">
          <motion.div
            variants={variants}
            custom={2}
            className="text-[24px] text-center lg:text-left lg:text-[40px] font-bold h-fit tracking-tight md:tracking-wider"
          >
            Hello! I'm{" "}
            <p className="text-[#4452FB] text-[40px] lg:text-6xl leading-tight">
              Anish Pradhan.
            </p>
            I am a <span className="text-[#10C296]">Python Full-Stack</span> Developer.
          </motion.div>
          <motion.div
            variants={variants}
            custom={3}
            className="text-[16px] md:text-[20px] lg:text-[24px] lg:max-w-2xl font-medium text-left"
          >
            My passion for developing is unmatched. Every day I wake up pumped
            to collaborate, design and code. I love every aspect of bringing an
            idea to fruition and find each step of the way exciting.
          </motion.div>
          <motion.div variants={variants} custom={4} className="flex items-start">
            <button
              onClick={() =>
                document.getElementById("Contact").scrollIntoView(true)
              }
              className="ring-2 ring-[#115343] hover:ring-[#10C296] duration-300 px-5 py-2 xl:px-8 xl:py-4 text-[#10C296] rounded-lg "
            >
              Get In Touch
            </button>
          </motion.div>
          <motion.div variants={variants} custom={5} className="h-fit">
            <button
              className="group text-[16px] md:text-[20px] lg:text-[24px] flex w-fit items-center space-x-4 "
              onClick={() =>
                document.getElementById("About").scrollIntoView(true)
              }
            >
              <motion.span
                whileTap={{ scale: 0.95 }}
                className="text-[#10C296]"
              >
                Know more about me
              </motion.span>{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 duration-300 animate-pulse"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2.5}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 14l-7 7m0 0l-7-7m7 7V3"
                />
              </svg>
            </button>
          </motion.div>
          </div>
        </motion.div>
        
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={1}
          className="grow flex justify-center xl:justify-end"
        >
          <div className="w-fit h-fit lg:h-[500px] overflow-hidden  duration-700 ">
          <img src={illustration} alt="..." className="h-full w-full" />
          </div>
          
        </motion.div>
      </div>
    </div>
  );
};

export { Home };
