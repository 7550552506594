import React from 'react';
import { profile } from "../Assets";
import { motion } from "framer-motion";
import { FaReact } from "react-icons/fa";
import { SiDjango } from "react-icons/si";
import { DiJavascript1 } from "react-icons/di";

export const ProfileImage = () => {
    return (
        <div className='relative self-center lg:self-auto w-[260px] h-[260px] md:w-[320px] xl:w-[480px] flex-none md:h-[320px] xl:h-[480px] rounded-full'>
            <motion.div className="w-[260px] h-[260px] md:w-[320px] xl:w-[480px] flex-none md:h-[320px] xl:h-[480px] overflow-hidden  duration-700  rounded-full border-[18px] border-transparent ring-4 ring-[#10C296]">
            <img src={profile} alt="profile" />

            <motion.div
              className="absolute bottom-[-40px] right-[34%] md:right-[37%] bg-gradient-to-b from-[#0071CB] to-[#0D161D] rounded-full p-3 cursor-pointer"
              drag
              dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
              dragElastic={0.7}
              whileHover={{
                y: -25,
                transition: { duration: 0.5 },
              }}
              whileTap={{
                scale: 0.85,
              }}
              animate={{ y: 0, transition: { duration: 0.5 } }}
            >
              <FaReact color="#05d3f7" className="w-12 h-12 xl:h-20 xl:w-20" />
            </motion.div>
            <motion.div
              className="absolute bottom-[-5px] right-[10%] md:right-[16%] lg:right-[16%] xl:right-[19%] md:bottom-[-6px] bg-[#092d1f] rounded-full p-3 hover:cursor-pointer"
              drag
              dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
              dragElastic={0.7}
              whileHover={{
                y: -20,
                x: 0,
                transition: { duration: 0.5 },
              }}
              whileTap={{
                scale: 0.85,
              }}
              animate={{ y: 0, x: 0, transition: { duration: 0.5 } }}
            >
              <SiDjango
                className="w-7 h-7 md:w-9 md:h-9 xl:h-12 xl:w-12"
                color="#eeeeee"
              />
            </motion.div>
            <motion.div
              className="absolute bottom-[-4px] right-[65%] md:right-[61%] md:bottom-[-10px] lg:bottom-[-6px] bg-[#fce403] rounded-full p-3 hover:cursor-pointer"
              drag
              dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
              dragElastic={0.7}
              whileHover={{
                y: -20,
                x: 0,
                transition: { duration: 0.5 },
              }}
              whileTap={{
                scale: 0.85,
              }}
              animate={{ y: 0, x: 0, transition: { duration: 0.5 } }}
            >
              <DiJavascript1
                className="w-7 h-7 md:w-9 md:h-9 xl:h-12 xl:w-12"
                color="black"
              />
            </motion.div>
          </motion.div>
        </div>
    );
}
