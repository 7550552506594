import React, {useEffect, useRef} from "react";
import { motion } from "framer-motion";
import MenuToggle from "./MenuToggle";
import { Navigation } from "./Navigation";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 260px 100px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(24px at 143px 45px)",
    transition: {
      delay: 0.1,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

export const NavBar = ({toggle, setToggle}) => {
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if(toggle && !ref.current.contains(event.target)){
        setToggle();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  });
 


  return (

      <motion.div
        ref={ref}
        className={`fixed flex justify-center top-0 bottom-0 right-0 w-[200px] bg-white drop-shadow-lg z-10 `}
        variants={sidebar}
        initial={false}
        animate={toggle ? "open" : "closed"}
      >
        <MenuToggle toggle={toggle} setToggle={() => setToggle()} />
        <Navigation toggle={toggle} setToggle={setToggle} />
      </motion.div>
  
  );
};
