import React, { useEffect} from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import VerticalTab from "../../Components/VerticalTab";

const Experience = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  const divVariants = {
    hidden: {
      y: 25,
      opacity: 0,
    },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: `${i * 0.2}`,
        duration: 0.3,
      },
    }),
  };

  return (
    <div
      id="Experience"
      ref={ref}
      className="flex justify-center mt-24 px-6 md:px-20 lg:px-40 2xl:px-80 "
    >
         <motion.div
        initial="hidden"
        animate={controls}
        variants={divVariants}
        className="flex relative flex-col justify-evenly w-full space-y-24 py-12"
      >
        <motion.h1
          variants={divVariants}
          custom={1}
          className="text-center text-[28px] lg:text-[48px] font-bold leading-none "
        >
         My
          <span className="bg-gradient-to-r from-red-500 to-slate-800 bg-clip-text text-transparent">
            {" "}
            Experiences
          </span>
        </motion.h1>

        <motion.div
          variants={divVariants}
          custom={2}
          className="text-center text-lg font-semibold leading-[28px]"
        >
          

        <VerticalTab />


        </motion.div>


      </motion.div>
    </div>
  );
};

export {Experience};
