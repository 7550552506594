import React from "react";
import { motion } from "framer-motion";
import { RiContactsFill } from 'react-icons/ri';
import { BiDetail } from 'react-icons/bi';
import { MdWork } from 'react-icons/md';
import { GrWorkshop } from 'react-icons/gr';
import { BsFillPersonCheckFill } from 'react-icons/bs';


const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.02 },
  },
  closed: {
    transition: { staggerChildren: 0.05, delayChildren: -1 },
  },
};
const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};
const colors = ["#ff008c", "#d309e1", "#7700ff"];
export const Navigation = ({toggle, setToggle}) => (
  <>
    <motion.ul className="flex flex-col justify-center p-[45px] space-y-10 w-full" variants={variants}>
      
      {itemIds.map((i, index) => {
        return (
          <motion.li
            className={`text-black lg:text-xl font-bold hover:text-[${colors[index]}]`}
            key={index}
            variants={itemVariants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setToggle(!toggle)}
          >
              <button className="flex items-center space-x-4" onClick={() => {document.getElementById(i.title).scrollIntoView(true)}}>
            {i.icon}
            <div className="text ">{i.title}</div></button>
            
          </motion.li>
        );
      })}
    </motion.ul>
  </>
);
const itemIds = [
  { icon:<BsFillPersonCheckFill/>, title: "About" },
  { icon:<MdWork/>, title: "Skills" },
  { icon:<BiDetail/>, title: "Portfolio" },
  { icon:<GrWorkshop />, title: "Experience" },
  { icon:<RiContactsFill/>, title: "Contact" },
];
