import React from "react";
import { motion } from "framer-motion";

const Path = (props) => (
  <motion.path
    strokeWidth="3"
    stroke="hsl(0, 0%, 0%)"
    strokeLinecap="round"
    {...props}
  />
);

const MenuToggle = ({ toggle, setToggle }) => {
  return (
    <motion.button
      className={`${toggle ? "fixed" : "absolute"} flex top-[20px] right-[30px] items-center bg-white p-4 rounded-full self-center`}
      whileTap={{scale:0.90}}
      onClick={setToggle}
      animate={toggle ? "open" : "closed"}
    >
      <svg width="22" height="22" viewBox="0 0 23 23">
        <Path
          variants={{
            closed: { d: "M 2 2.5 L 20 2.5" },
            open: { d: "M 3 16.5 L 17 2.5" },
            transition: {
              duration: 1,
            },
          }}
        />
        <Path
          d="M 2 9.423 L 20 9.423"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
            transition: {
              duration: 1,
            },
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          variants={{
            closed: { d: "M 2 16.346 L 20 16.346" },
            open: { d: "M 3 2.5 L 17 16.346" },
            transition: {
              duration: 2,
            },
          }}
        />
      </svg>
    </motion.button>
  );
};

export default MenuToggle;
