import React, { useEffect} from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { signin, chatbot2, brsHome, portfolioImg } from "../../Assets";
import Project from "../../Components/Project";

const Portfolio = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  const divVariants = {
    hidden: {
      y: 25,
      opacity: 0,
    },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: `${i * 0.2}`,
        duration: 0.5,
      },
    }),
  };

  // const total = [
  //   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  //   22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
  // ];

  const cardData = [
    {
      title: "Multi-tenant LiveChat Support System",
      subtitle: "Instant messaging platform for customers.",
      desc: "Live chat support is a way for customers to get help through instant messaging platforms. It happens on a one on one level, often via a company's website. It can be a proactive chat pop-up like a chat box appearing on your screen and asking if you need help.",
      img: signin,
      github: "https://github.com/anishpradhan/Livechat",
      tech: ["Django", "React", "RestAPI", "Redux", "Socket", "Tailwind Css", "OAuth"],
    },

    {
      title: "VolgAi Chatbot",
      subtitle: "VolgAI web AI powered chatbot with rasa stack",
      desc: "A complex chatbot system that can be customized and integrated in any website to provide contextual support in an automated and effecient way.",
      img: chatbot2,
      bg: "#344E41",
      github: "https://github.com/anishpradhan/Rasa-Chatbot",
      tech: ["Rasa", "Python", "Flask", "RestAPI"],
    },
    {
      title: "Book Recommendation System",
      subtitle: "A simple book recommendation system",
      desc: "Get the best books recommendation of your choice powered by multiple strong machine learning algorithms. It was created with Django and Bootstrap.",
      img: brsHome,
      bg: "#344E41",
      github: "https://github.com/anishpradhan/BRS",
      tech: ["Python", "Django", "Sklearn", "Pandas", "Numpy", "Bootstrap", "Ajax", "BeautifulSoup"],
    },
    {
      title: "Portfolio Website",
      subtitle: "My personal portfolio website",
      desc: "Get to know more about me personally and professionally through this portfolio which I developed personally.",
      img: portfolioImg,
      bg: "#344E41",
      github: "https://github.com/anishpradhan/Portfolio",
      tech: ["React", "Tailwind Css", "Framer Motion", "React Icons", "Email Js"],
    },
  ];

  // const [isMoved, setIsMoved] = useState(false);

  // const handleClick = (direction) => {
  //   setIsMoved(true);

  //   if (rowRef.current) {
  //     const { scrollLeft, clientWidth } = rowRef.current;
  //     const scrollTo =
  //       direction === "left"
  //         ? scrollLeft - clientWidth
  //         : scrollLeft + clientWidth;
  //     rowRef.current.scrollTo({ left: scrollTo, behavior: "smooth" });
  //   }
  // };

  return (
    <div
      id="Portfolio"
      ref={ref}
      className="flex justify-center mt-24 sm:min-h-screen px-6 md:px-20 lg:px-40 2xl:px-80 "
    >
      <motion.div
        initial="hidden"
        animate={controls}
        variants={divVariants}
        className="flex relative flex-col justify-evenly w-full space-y-10 py-12"
      >
        <motion.h1
          variants={divVariants}
          custom={1}
          className="text-center text-[28px] lg:text-[48px] font-bold leading-none "
        >
          Portfolio
          <span className="bg-gradient-to-r from-red-500 to-slate-800 bg-clip-text text-transparent">
            {" "}
            Projects
          </span>
        </motion.h1>

        {/* <div className="relative">
          <div
            className="absolute bg-pink-400 top-0 left-0 cursor-pointer"
            onClick={() => handleClick("left")}
          >
            left
          </div>
          <div
            ref={rowRef}
            className="flex items-center space-x-0.5 overflow-x-scroll"
          >
            {total.map((i) => {
              return (
                <div key={i} className="h-24 flex items-center px-2">
                  Thumbnail photo {i}
                </div>
              );
            })}
          </div>
          <div
            className="absolute bg-pink-400 top-0 right-0 cursor-pointer"
            onClick={() => handleClick("right")}
          >
            right
          </div>
        </div> */}
        <motion.h3
          variants={divVariants}
          custom={2}
          className="text-center text-lg font-semibold leading-[28px]"
        >
          Here are some examples below of what I've created.
        </motion.h3>

        <motion.div
          variants={divVariants}
          custom={3}
          className="flex flex-col space-y-32 "
        >
          {cardData.map((i, index) => {
            return <Project key={index} i={i} />;
          })}
        </motion.div>


      </motion.div>
    </div>
  );
};

export { Portfolio };
