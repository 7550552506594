import React from "react";

const Project = ({ i }) => {
  
  return (
    <div className="relative flex md:flex-row flex-col-reverse w-full space-y-reverse space-y-10 md:space-y-0 justify-between ">
      <div className="desc flex md:w-2/6 z-20 ">
        <div className="md:absolute flex flex-col h-fit md:top-1/2 md:-translate-y-1/2 justify-center text-left space-y-4 md:w-3/6">
          <div>
            <h2 className="text-xl md:text-2xl text-[#10C296] font-bold">{i.title}</h2>
            <h4 className="text-md leading-normal font-semibold">{i.subtitle}</h4>
          </div>
          <div className="md:bg-white md:text-black rounded-lg md:p-5 shadow-black shadow-2xl md:ring-1 md:ring-gray-200 text-base leading-wide tracking-normal">
            {i.desc}
          </div>
          <ul className="flex flex-wrap space-x-2 text-sm leading-[20px]">
            
            {i.tech.map((tech, index) => {
              return <li key={index} className="flex space-x-2 flex-none"><span>{tech}</span> {index < i.tech.length -1 &&  (<span>•</span>)}</li>;
            })}
            </ul>
          
        </div>
      </div>
      <div className="img h-full md:w-4/6 flex justify-start ">
        <a href={i.github} title="Visit Github" target="_blank" rel="noreferrer" className="" >
          <img
            src={i.img}
            alt="..."
            className="md:h-full w-full flex justify-center md:hover:scale-105 duration-500 z-0 rounded-xl overflow-hidden md:filter md:brightness-50 hover:brightness-100 shadow-white shadow-xl"
          />
         
        </a>
      </div>
    </div>
  );
};

export default Project;
