import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
// import {profile} from "../../Assets";
import { BsFacebook, BsLinkedin, BsGithub } from "react-icons/bs";
import { ProfileImage } from "../../Components/ProfileImage";
import { Resume } from "../../Assets";

const About = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  const divVariants = {
    hidden: {
      y: 25,
      opacity: 0,
    },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: `${i * 0.3}`,
        duration: 0.5,
      },
    }),
  };

  return (
    <div
      id="About"
      ref={ref}
      className="flex justify-center px-6 md:px-20 lg:px-40 2xl:px-80
    "
    >
      <motion.div
        initial="hidden"
        animate={controls}
        variants={divVariants}
        className="flex flex-col w-full space-y-20 py-12"
      >
        <motion.h1
          variants={divVariants}
          custom={1}
          className="text-[28px] lg:text-[48px] font-bold leading-none "
        >
          About
          <span className="bg-gradient-to-r from-red-500 to-slate-800 bg-clip-text text-transparent">
            {" "}
            Me
          </span>
        </motion.h1>
        <div className="flex flex-col lg:flex-row space-y-12 lg:space-y-0 justify-around ">
          <motion.div variants={divVariants} custom={2} className="self-center">
            <ProfileImage />
          </motion.div>
          <motion.div
            variants={divVariants}
            custom={3}
            className="flex flex-col justify-evenly text-[16px] md:text-[18px] lg:text-[22px] sm:px-5 lg:w-[60%] space-y-4 font-medium text-left "
          >
            <span>
              Hello! My name is Anish Pradhan and I enjoy creating things that
              live on the internet, whether that be websites, applications, or
              anything in between. As a Full-Stack developer, I bridge the gap
              between engineering and design. I always have a keen eye for
              aesthetics to create a beautiful product.
              <br />
              <br />
              My goal is to build applications that are scalable and efficient
              under the hood while providing engaging, pixel-perfect user
              experiences.
            </span>
            <div className="flex space-x-8 items-center flex-wrap space-y-3">
              <motion.a
                variants={divVariants}
                href={Resume}
                target="__blank"
                className="self-start border-2 border-[#4452FB] hover:bg-[#4452FB] hover:text-white duration-300 px-5 py-2 text-[#4452FB] rounded-lg text-lg"
              >
                Resume
              </motion.a>
              <div className="flex space-x-4">
                <a
                  href="https://www.linkedin.com/in/anishpdhn/"
                  target={"_blank"}
                  rel="noreferrer"
                  className="bg-white rounded-[30px] "
                >
                  <BsLinkedin size={30} color={"#2563EB"} />
                </a>
                <a
                  href="https://www.facebook.com/anish.pradhan.902266"
                  target={"_blank"}
                  rel="noreferrer"
                  className="bg-white rounded-full"
                >
                  <BsFacebook size={30} color="#0e8ef1" />
                </a>
                <a
                  href="https://github.com/anishpradhan"
                  target={"_blank"}
                  rel="noreferrer"
                  className=""
                >
                  <BsGithub size={30} />
                </a>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export { About };
