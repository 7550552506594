import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoIosArrowUp } from "react-icons/io";
import { useWindowScroll } from "react-use";

import { useEffect, useState } from "react";

const upVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.7 } },
  exit: { opacity: 0, transition: { duration: 0.7 } },
};

const ScrollToTop = () => {
  const { y: pageYOffset } = useWindowScroll();
  const [visible, setVisibility] = useState(false);

  useEffect(() => {
    pageYOffset > 400 ? setVisibility(true) : setVisibility(false);
  }, [pageYOffset]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <>
      <AnimatePresence>
        {visible && (
          <motion.a
            onClick={scrollToTop}
            variants={upVariant}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="fixed bottom-7 hover:cursor-pointer right-7 w-14 h-14 rounded-full pt-2 bg-white flex items-center justify-center shadow-2xl z-10"
          >
            <span className="animate-bounce">
              <IoIosArrowUp size="32" color="black" />
            </span>
          </motion.a>
        )}
      </AnimatePresence>
    </>
  );
};

export default ScrollToTop;
