import React, { useRef, useState, useEffect } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { MdOutlineClose } from "react-icons/md";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import emailjs from "emailjs-com";

const Contact = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  const divVariants = {
    hidden: {
      y: 25,
      opacity: 0,
    },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: `${i * 0.2}`,
        duration: 0.3,
      },
    }),
  };

  const [msgState, setMsgState] = useState(null);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setMsgState("sending");
    emailjs
      .sendForm(
        "service_okcof5d",
        "template_w0a7f29",
        form.current,
        "fv55uDPBv92-cs4z0"
      )
      .then(
        (result) => {
          console.log(result.text);
          result.text === "OK" && setMsgState("sent");
        },
        (error) => {
          console.log(error.text);
          setMsgState("Error");
        }
      );
    e.target.reset();
  };

  return (
    <div
      id="Contact"
      ref={ref}
      className="flex justify-center mt-24 px-6 md:px-20 lg:px-40 2xl:px-80"
    >
      <motion.div
        initial="hidden"
        animate={controls}
        variants={divVariants}
        className="w-full flex flex-col justify-between space-y-8 py-12"
      >
        <div className="flex flex-col space-y-4 lg:space-y-10 max-w-4xl">
          <motion.span  variants={divVariants}
          custom={1} className="text-xl lg:text-3xl leading-[37px] lg:leading-[65px] font-bold">
            Please feel free to contact me.
          </motion.span>
          <motion.span variants={divVariants}
          custom={2} className="text-base lg:text-xl leading-[24px] lg:leading-[38px] text-left">
            If you have a project in mind or are simply interested in finding
            out more, please fill the form below and let’s get things moving.
          </motion.span>
          <div className="max-w-[510px]">
            {msgState === "sent" && (
              <div className="w-full h-12 flex mb-4 rounded-lg overflow-hidden">
                <div className="w-10 flex justify-center items-center bg-[#83B757]">
                  <IoMdCheckmarkCircle size={25} color="white" />
                </div>
                <div className="grow bg-[#DFF2D5] text-black flex items-center px-4">
                  Your email has been successfully sent.
                </div>
                <div className="w-10 flex justify-center items-center bg-[#DFF2D5]">
                  <span
                    className="cursor-pointer w-fit"
                    onClick={() => setMsgState(null)}
                  >
                    <MdOutlineClose color="black" size={30} />
                  </span>
                  {setTimeout(() => {
                    setMsgState(null);
                  }, 5000)}
                </div>
              </div>
            )}
            {msgState === "Error" && (
              <div className="w-full h-12 flex mb-4 rounded-lg overflow-hidden">
                <div className="w-10 flex justify-center items-center bg-[#C43124]">
                  <IoMdCloseCircle size={25} color="white" />
                </div>
                <div className="grow bg-[#FDE3DE] text-black flex items-center px-4">
                  Error while sending email! Please try again.
                </div>
                <div className="w-10 flex justify-center items-center bg-[#FDE3DE]">
                  <span
                    className="cursor-pointer w-fit"
                    onClick={() => setMsgState(null)}
                  >
                    <MdOutlineClose color="black" size={30} />
                  </span>
                </div>
              </div>
            )}
            <form
              ref={form}
              onSubmit={sendEmail}
              className="flex flex-col space-y-8"
            >
              <motion.div  variants={divVariants}
          custom={3} className="flex space-x-4 h-14">
                <div className="relative grow">
                  <input
                    type="text"
                    id="first_name"
                    name="from_fname"
                    size={10}
                    required
                    className="block px-5 w-full h-full text-sm text-white bg-[#121212] rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label className="absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-90 top-2 z-10 origin-[0]  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 left-1">
                    First Name
                  </label>
                </div>
                <div className="relative grow">
                  <input
                    type="text"
                    id="last_name"
                    name="from_lname"
                    size={10}
                    required
                    className="block px-5 w-full h-full text-sm text-white bg-[#121212] rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label className="absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-90 top-2 z-10 origin-[0]  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 left-1">
                    Last Name
                  </label>
                </div>
              </motion.div>
              <div className="relative ">
                <input
                  type="email"
                  id="email"
                  name="from_email"
                  required
                  className="block px-5 h-14 w-full text-sm text-white bg-[#121212] rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label className="absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-90 top-2 z-10 origin-[0]  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 left-1">
                  Email
                </label>
              </div>

              <div className="relative">
                <textarea
                  name="message"
                  id="message"
                  className="block px-5 py-4 h-36 w-full text-sm text-white bg-[#121212] rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label className="absolute  text-sm text-gray-400 duration-300 transform -translate-y-4 scale-90 top-2 z-10 origin-[0]  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 left-1">
                  Message
                </label>
              </div>

              <button
                type="submit "
                className="group  text-white self-start text-lg leading-[38px] lg:text-xl font-extrabold "
              >
                {!msgState || msgState === "sent" ? (
                  <span className="flex space-x-3 items-center"> <h2>Submit</h2> <BsArrowRightShort
                  size={40}
                  className="group-hover:translate-x-3 duration-200"
                /></span>
                ) : (
                  <div className="flex space-x-2 items-center">
                    
                    <span className="">Sending...</span>
                    <span className="animate-spin h-fit">
                      <AiOutlineLoading3Quarters />
                    </span>
                  </div>
                 )
                }
                
              </button>
            </form>
          </div>
        </div>
        <div className="flex md:mt-0 mt-8">
          <motion.span variants={divVariants}
          custom={4} className="text-base  lg:text-xl md:leading-[54px] leading-[30px] font-bold ">
            For everything else, you can email to{" "}
            <a href="mailto:anishpdhn@gmail.com" className="text-[#11cc9e]">
              anishpdhn@gmail.com
            </a>
          </motion.span>
        </div>
      </motion.div>
    </div>
  );
};

export { Contact };
